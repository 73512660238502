@if (!isHidden() && checkAnyOptionHasRole([currentRole()])) {
<h3 class="w-full text-left opacity-70">
  {{ menuTitle() | translate }}
</h3>
}

<mat-tree [dataSource]="treeSource" [treeControl]="treeControl" class="bg-transparent menu-tree">
  <!-- Expandable -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: nodeHasChild">
    <button mat-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" class="node">
      @if (node.icon) { <mat-icon class="align-middle scale-125 opacity-75">{{ node.icon }}</mat-icon> }
      <span>{{ node.name }}</span>
      <mat-icon iconPositionEnd [ngClass]="{'rotate-180': treeControl.isExpanded(node)}"
        class="ml-auto scale-125 transition-height text-monochrome-dark dark:text-monochrome-lightest">
        expand_more
      </mat-icon>
    </button>
    <ul [@slideVertical]="treeControl.isExpanded(node) ? 'show' : null" class="overflow-clip" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </ul>
  </mat-nested-tree-node>

  <!-- Leaf -->
  <mat-tree-node *matTreeNodeDef="let node" class="min-h-[2.5rem]">
    <a mat-button routerLink={{node.redirectUrl}} (click)="closeMenuEventEmit()" class="node"
      [ngClass]="{'bg-yellow-deep/50 dark:bg-black/30': isCurrentActiveMenuItem(node.redirectUrl)}">
      @if (node.icon) { <mat-icon class="align-middle scale-125 opacity-75">{{ node.icon }}</mat-icon> }
      @if (node.redirectUrl === '/deviations') {
      <span [matBadge]="badgeCount()" matBadgeOverlap="false" [matBadgeColor]="getBadgeColor()"
        [matBadgeHidden]="!badgeCount()">
        {{ node.name }}
      </span>
      } @else {
      <span>{{ node.name }}</span>
      }
    </a>
  </mat-tree-node>
</mat-tree>