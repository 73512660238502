import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PaginatorIntl {
  constructor(private translate: TranslateService) {}

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    const ofLabel = this.translate ? this.translate.instant('ui.ofLabel') : 'av';
    if (length === 0 || pageSize === 0) {
      return `0 ${ofLabel} ${length}`;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${ofLabel} ${length}`;
  };

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = this.translate.instant('ui.itemsPerPage');
    paginatorIntl.firstPageLabel = this.translate.instant('ui.firstPage');
    paginatorIntl.lastPageLabel = this.translate.instant('ui.lastPage');
    paginatorIntl.nextPageLabel = this.translate.instant('ui.nextPage');
    paginatorIntl.previousPageLabel = this.translate.instant('ui.previousPage');
    paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);

    return paginatorIntl;
  }
}

