import { Injectable, Provider } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';

export const NORWEGIAN_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/**
 * A simple date adapter for the Norwegian date format (DD.MM.YYYY), since the built-in
 * adapter does not support parsing this format.
 */
@Injectable()
export class NorwegianDateAdapter extends NativeDateAdapter {
  constructor() {
    super('nb-NO');
  }

  parse(value: string | null): Date | null {
    if (typeof value === 'string') {
      const parts = value.split('.');
      if (parts.length === 3) {
        let year = Number(parts[2]);
        const month = Number(parts[1]) - 1;
        const date = Number(parts[0]);

        if (year < 100) {
          year += 2000;
        }

        return new Date(year, month, date);
      }
    }

    return super.parse(value);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}

export function norwegianDateAdapterProvider(): Provider[] {
  return [
    { provide: DateAdapter, useClass: NorwegianDateAdapter, deps: [MAT_DATE_FORMATS] },
    { provide: MAT_DATE_FORMATS, useValue: NORWEGIAN_DATE_FORMATS },
  ];
}
