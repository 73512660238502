import { FuelService } from "@app/core/http/fuel.service";
import { FUELS_FETCH, FUELS_LOAD_FAILURE, FUELS_LOAD_SET, FUELS_LOAD_SUCCESS } from "./fuels.actions";
import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { concatLatestFrom } from "@ngrx/operators";
import { EMPTY, catchError, map, mergeMap, of, tap } from "rxjs";
import { APIerror } from "@app/models/common/error.class";
import { get_fuels, get_fuelsLoading } from "./fuels.selectors";

export const loadFuelds = createEffect(
  (
    $actions = inject(Actions),
    fuelService = inject(FuelService),
    store = inject(Store)
  ) => {
    return $actions.pipe(
      ofType(FUELS_FETCH),
      concatLatestFrom(() => [
        store.pipe(select(get_fuels())),
        store.pipe(select(get_fuelsLoading()))
      ]),
      // First element of the array is the action, which isn't needed. Empty and unnamed value to not trigger linting errors
      mergeMap(([, fuels, loading]) => {
        // StationGroups already loaded
        if (fuels.length) {
          return of(FUELS_LOAD_SUCCESS({ fuels: fuels }));

          // StationGroups are loading
        } else if (loading) {
          return EMPTY;
        }

        // Load stationGroups
        store.dispatch(FUELS_LOAD_SET());
        return fuelService.getFuels().pipe(
          tap(() => store.dispatch(FUELS_LOAD_SET())),
          map(result => FUELS_LOAD_SUCCESS({ fuels: result.items })),
          catchError((error: APIerror) => of(FUELS_LOAD_FAILURE({ error: error })))
        )
      })
    )
  }, { functional: true }
)
