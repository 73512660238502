import { createReducer, on } from '@ngrx/store';
import { Station } from '@models/station/station.class';
import { STATIONS_LOAD_FAILURE, STATIONS_LOAD_SET, STATIONS_LOAD_SUCCESS, STATIONS_SET, STATION_UPDATE } from './stations.actions';

export interface StationsState {
  stations: Station[];
  loading: boolean;
}

export const initialState: StationsState = {
  stations: [],
  loading: false
}

export const stationsReducer = createReducer(
  initialState,
  on(STATIONS_SET, (state, { stations: stations }) => {
    return { ...state, stations: stations };
  }),
  on(STATION_UPDATE, (state, { station }) => {
    const newState = [...state.stations];
    const index = newState.findIndex(s => s.id === station?.id);
    if (index !== -1) newState[index] = station;
    else newState.push(station);

    return { ...state, stations: newState };
  }),
  on(STATIONS_LOAD_SET, (state) => {
    return { ...state, loading: true };
  }),
  on(STATIONS_LOAD_SUCCESS, (state, { stations }) => {
    return { ...state, stations: stations, loading: false };
  }),
  on(STATIONS_LOAD_FAILURE, (state) => {
    return { ...state, loading: false };
  })
);
