import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { selectCurrentUserHighestRoleAllocation, selectUserName } from '@store/currentUser/currentUser.selectors';
import { LoginService } from '@services/login.service';
import { RoleEnum } from '@models/common/roles.enum';
import { MenuOption } from '@models/menuOption/menuOption.class';
import AccessHelper from '@helpers/access.helper';
import { environment } from '@environments/environment';
import { ConfirmationDialogComponent } from '@common/confirmation-dialog/confirmation-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LayoutMenuSectionComponent } from './layout-menu-section/layout-menu-section.component';

import { RouterLink, RouterOutlet } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import ChangeLogHelper from '@app/core/helpers/changelog.helper';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('slideVertical', [
      state('*', style({ height: 0 })),
      state('show', style({ height: '*' })),
      transition('* => *', [animate('200ms cubic-bezier(0.25, 0.8, 0.25, 1)')])
    ]),
    trigger('toggleMenuOpen', [
      state('open', style({
        transform: 'rotate(-180deg)'
      })),
      state('closed', style({
        transform: 'rotate(0deg)'
      })),
      transition('* => *', [animate('150ms')])
    ]),
    trigger('toggleExpandOpen', [
      state('open', style({
        transform: 'rotate(-90deg)'
      })),
      state('closed', style({
        transform: 'rotate(0deg)'
      })),
      transition('* => *', [animate('150ms')])
    ]),
  ],
  standalone: true,
  imports: [
    LayoutMenuSectionComponent,
    MatBadgeModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    RouterLink,
    RouterOutlet,
    TranslateModule
  ]
})

export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    this.sidenav.toggle();
  }
  private readonly _destroying$ = new Subject<void>();

  // Menu variables
  treeControl = new NestedTreeControl<MenuOption>(node => node.subOptions);
  globalMenuOptions: MenuOption[] = [];
  logisticsMenuOptions: MenuOption[] = [];
  settlementMenuOptions: MenuOption[] = [];
  priceMenuOptions: MenuOption[] = [];
  operationsMenuOptions: MenuOption[] = [];
  menuDropdown: MenuOption[] = [];

  // View helpers
  localStorage = localStorage;
  Role = RoleEnum;
  isIframe: boolean = false;
  isLoggedIn?: boolean = false;
  environment = environment;

  // User info
  usersName?: string;
  currentRole!: RoleEnum;

  constructor(
    private store: Store,
    private observer: BreakpointObserver,
    private changeLogHelper: ChangeLogHelper,
    private loginService: LoginService,
    public dialog: MatDialog,
    private t: TranslateService
  ) { }

  ngOnInit(): void {
    this.store.select(selectUserName).pipe(takeUntil(this._destroying$)).subscribe((usersName: string | undefined) => this.usersName = usersName);
    this.store.select(selectCurrentUserHighestRoleAllocation).pipe(takeUntil(this._destroying$)).subscribe((role: RoleEnum) => this.currentRole = role);
    this.loginService.getLoginStatus().pipe(takeUntil(this._destroying$)).subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn);

    this.globalMenuOptions = [
      new MenuOption(this.t.instant('menu.stations'), AccessHelper.getViewAccess("stations"), "/stations", "store"),
      new MenuOption(this.t.instant('menu.products'), AccessHelper.getViewAccess("products"), "/products", "playlist_add"),
      new MenuOption(this.t.instant('menu.onboarding'), AccessHelper.getViewAccess("onboarding"), "/onboarding", "update"),
      new MenuOption(this.t.instant('menu.users'), AccessHelper.getViewAccess("users"), "/users", "group"),
    ];

    this.logisticsMenuOptions = [
      new MenuOption(this.t.instant('menu.tanks'), AccessHelper.getViewAccess("tanks"), "/tanks", "opacity"),
      new MenuOption(this.t.instant('menu.deliveries'), AccessHelper.getViewAccess("deliveries"), "/deliveries", "departure_board")
    ];

    this.settlementMenuOptions = [
      new MenuOption(this.t.instant('menu.sales'), AccessHelper.getViewAccess("sales"), "/sales", "receipt"),
      new MenuOption(this.t.instant('menu.settlement'), AccessHelper.getViewAccess("settlements"), "/settlements", "receipt_long"),
      new MenuOption(this.t.instant('menu.deviation'), AccessHelper.getViewAccess("deviations"), "/deviations", "checklist"),
    ];

    this.priceMenuOptions = [
      new MenuOption(this.t.instant('menu.priceOverview'), AccessHelper.getViewAccess("price"), "/price", "payments"),
      new MenuOption(this.t.instant('menu.priceObservations'), AccessHelper.getViewAccess("priceObservations"), undefined, "query_stats",
        [
          new MenuOption(this.t.instant('menu.registerPriceObservations'), AccessHelper.getViewAccess("newPriceObservations"), "/newPriceObservations"),
          new MenuOption(this.t.instant('menu.priceObservationsHistory'), AccessHelper.getViewAccess("priceObservationsHistory"), "/priceObservationsHistory"),
          new MenuOption(this.t.instant('menu.observationRoutes'), AccessHelper.getViewAccess("observationRoutes"), "/observationRoutes"),
        ]),
      new MenuOption(this.t.instant('menu.priceLift'), AccessHelper.getViewAccess("priceLift"), "/priceLift", "trending_up"),
      new MenuOption(this.t.instant('menu.priceRuleMangement'), AccessHelper.getViewAccess("priceConfig"), undefined, "rule_settings",
        [
          new MenuOption(this.t.instant('menu.priceRules'), AccessHelper.getViewAccess("priceRules"), "/priceRules"),
          new MenuOption(this.t.instant('menu.priceStationDetails'), AccessHelper.getViewAccess("priceStationDetails"), "/priceStationDetails"),
          new MenuOption(this.t.instant('menu.competitorConfig'), AccessHelper.getViewAccess("competitorConfig"), "/competitorConfig"),
        ]),
    ];

    this.operationsMenuOptions = [
      new MenuOption(this.t.instant('menu.alarms'), AccessHelper.getViewAccess("alarms"), "/alarms", "notifications"),
      new MenuOption(this.t.instant('menu.relays'), AccessHelper.getViewAccess("relays"), "/relays", "flash_off")
    ];

    this.menuDropdown = [
      new MenuOption(this.t.instant('menu.myPage'), AccessHelper.getViewAccess("/my-page"), "/my-page", "face"),
      new MenuOption(this.t.instant('ui.changelog'), AccessHelper.getViewAccess("changelog"), "/changelog", "update"),
      new MenuOption(this.t.instant('menu.logout'), AccessHelper.getViewAccess(""), "/logout", "logout"),
    ];

    this.isIframe = window !== window.parent && !window.opener;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.observer.observe(['(max-width: 1024px)']) // breakpoint matching tailwind's 'lg'
        .subscribe((res) => {
          if (res.matches) {
            this.sidenav.mode = 'over';
            this.sidenav.close();
          } else {
            this.sidenav.mode = 'side';
            this.sidenav.open();
          }
        });
    });
  }

  closeIfMobile = () => this.sidenav.mode == 'over' ? this.sidenav.close() : null;
  getDropdownMenuItem = (url: string) => this.menuDropdown.find(x => x.redirectUrl == url);

  toggleDarkMode(): void {
    if (localStorage.getItem('theme') === 'dark') {
      localStorage.theme = 'light';
      document.documentElement.classList.remove('dark');
      window.document.documentElement.style.colorScheme = 'light';
    } else {
      localStorage.theme = 'dark';
      document.documentElement.classList.add('dark');
      window.document.documentElement.style.colorScheme = 'dark';
    }
  }

  login = () => this.loginService.login();
  logOut(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: 'confirmations.youSure',
        icon: null,
        centerTitle: true,
        confirmButtonText: 'menu.logout',
        confirmButtonColor: 'accent',
        cancelButtonText: 'common.cancel'
      },
      panelClass: 'dialog-responsive'
    }).afterClosed().subscribe(result => {
      if (result) this.loginService.logout();
    });
  }

  getChangelogBadge = () => this.changeLogHelper.getNewLogsCount()();

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
