import { createReducer, on } from '@ngrx/store';
import { User } from '@models/user/user.class';
import {
  setUserAPIToken,
  setUserInformation,
  setUserName,
  setUserPermissions,
} from './currentUser.actions';

export const initialState: User = new User();

export const currentUserReducer = createReducer(
  initialState,
  on(setUserInformation, (state, { user }) => {
    if (!user.id) return state;

    return {
      id: user.id,
      displayName: user.displayName,
      firstName: user.firstName,
      lastName: user.lastName,
      userPrincipalName: user.userPrincipalName,
      identities: user.identities,
      role: user.role,
      email: user.email,
      apiToken: user.apiToken,
      mobilePhone: user.mobilePhone,
      roleAllocations: user.roleAllocations,
    };
  }),
  on(setUserAPIToken, (state, { token }) => {
    return { ...state, apiToken: token };
  }),
  on(setUserName, (state, { firstName, lastName }) => {
    return { ...state, firstName: firstName, lastName: lastName };
  }),
  on(setUserPermissions, (state, { roleAllocations }) => {
    return { ...state, roleAllocations: roleAllocations };
  })
);
