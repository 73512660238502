import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as defaultLanguage from '@assets/i18n/no.json';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environments/environment';
import { LayoutComponent } from '@components/layout/layout.component';
import { MsalService } from '@azure/msal-angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [LayoutComponent],
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private authService: MsalService,
    private destroyRef: DestroyRef,
    @Inject(DOCUMENT) private document: Document,
  ) {
    translate.setTranslation('no', defaultLanguage);
    translate.setDefaultLang('no');

    // Set favicon based on environment
    const favicon = this.document.querySelector('link[rel="icon"]');
    favicon?.setAttribute('href', environment.faviconPath);
  }

  ngOnInit(): void {
    this.authService.handleRedirectObservable().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }
}
