import { inject } from "@angular/core";
import { StationService } from "@app/core/http/station.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { STATIONGROUPS_FETCH, STATIONGROUPS_LOAD_FAILURE, STATIONGROUPS_LOAD_SET, STATIONGROUPS_LOAD_SUCCESS } from "./stationGroups.actions";
import { concatLatestFrom } from "@ngrx/operators";
import { get_stationGroups, get_stationGroupsLoading } from "./stationGroups.selectors";
import { EMPTY, catchError, map, mergeMap, of, tap } from "rxjs";
import { APIerror } from "@app/models/common/error.class";

export const loadStationGroups$ = createEffect(
  (
    $actions = inject(Actions),
    stationService = inject(StationService),
    store = inject(Store)
  ) => {
    return $actions.pipe(
      ofType(STATIONGROUPS_FETCH),
      concatLatestFrom(() => [
        store.pipe(select(get_stationGroups())),
        store.pipe(select(get_stationGroupsLoading()))
      ]),
      // First element of the array is the action, which isn't needed. Empty and unnamed value to not trigger linting errors
      mergeMap(([, stationGroups, loading]) => {
        // StationGroups already loaded
        if (stationGroups.length) {
          return of(STATIONGROUPS_LOAD_SUCCESS({ stationGroups: stationGroups }));

          // StationGroups are loading
        } else if (loading) {
          return EMPTY;
        }

        // Load stationGroups
        store.dispatch(STATIONGROUPS_LOAD_SET());
        return stationService.getStationGroups().pipe(
          tap(() => store.dispatch(STATIONGROUPS_LOAD_SET())),
          map(result => STATIONGROUPS_LOAD_SUCCESS({ stationGroups: result.items })),
          catchError((error: APIerror) => of(STATIONGROUPS_LOAD_FAILURE({ error: error })))
        )
      })
    )
  }, { functional: true }
)
