import { MenuOption } from './../../models/menuOption/menuOption.class';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export default class OnboardedHelper {
  public filterMenu(menu: MenuOption[]): MenuOption[] {
    // Filters out dropdown (option with suboptions) that has no onboarded suboptions, and options that isn't onboarded.
    return menu.filter(option => {
      const subOptions = option.subOptions?.filter(suboption => this.getViewOnboarded(suboption.redirectUrl!));
      return (!!subOptions && subOptions.length > 0) || (option.redirectUrl && this.getViewOnboarded(option.redirectUrl));
    });
  }

  // Update as needed with stages as they are added
  getViewOnboarded(viewPath: string): boolean {
    switch (viewPath.split('/')[1]) {
      case '':
      case 'alarmConfig':
      case 'alarms':
      case 'competitorConfig':
      case 'deliveries':
      case 'deviations':
      case 'newPriceObservations':
      case 'observationRoutes':
      case 'onboarding':
      case 'price':
      case 'priceConfig':
      case 'priceLift':
      case 'priceObservations':
      case 'priceObservationsHistory':
      case 'priceRules':
      case 'priceStationDetails':
      case 'products': // Fuels
      case 'relays':
      case 'sales':
      case 'settlements':
      case 'stations':
      case 'tanks':
      case 'users':
        return true;

      default:
        if (!environment.production)
          console.warn(`Onboarding not implemented for path ${viewPath.split('/')[1]}.`);
        return false;
    }
  }
}
