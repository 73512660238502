import { Router, ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { map, filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import AccessHelper from '@helpers/access.helper';
import { selectCurrentUserHighestRoleAllocation } from '../store/currentUser/currentUser.selectors';
import { RoleEnum } from '@app/models/common/roles.enum';

export const roleGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const store = inject(Store);
  const router = inject(Router);

  const currentRole$ = store.select(selectCurrentUserHighestRoleAllocation);
  const routingTo = route.routeConfig?.path ? route.routeConfig.path : '';

  const hasRole = currentRole$?.pipe(
    filter(currentRole => !!currentRole && currentRole !== RoleEnum.NoAccess),
    map(currentRole => {
      const hasAccess = AccessHelper.checkViewAccess(routingTo, currentRole);
      if (!hasAccess) {
        router.navigate(['']);
      }
      return hasAccess;
    }),
    take(1)
  );

  return hasRole;
};
