import { inject } from "@angular/core";
import { PriceService } from "@app/core/http/price.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { INFLUENCEREGIONS_FETCH, INFLUENCEREGIONS_LOAD_FAILURE, INFLUENCEREGIONS_LOAD_SET, INFLUENCEREGIONS_LOAD_SUCCESS } from "./influenceRegions.actions";
import { concatLatestFrom } from "@ngrx/operators";
import { get_influenceRegions, get_influenceRegionsLoading } from "./influenceRegions.selectors";
import { catchError, EMPTY, map, mergeMap, of, tap } from "rxjs";
import { APIerror } from "@app/models/common/error.class";

export const loadInfluenceRegions = createEffect(
  (
    $actions = inject(Actions),
    priceService = inject(PriceService),
    store = inject(Store)
  ) => {
    return $actions.pipe(
      ofType(INFLUENCEREGIONS_FETCH),
      concatLatestFrom(() => [
        store.pipe(select(get_influenceRegions())),
        store.pipe(select(get_influenceRegionsLoading()))
      ]),
      // First element of the array is the action, which isn't needed. Empty and unnamed value to not trigger linting errors
      mergeMap(([, influenceRegions, loading]) => {
        // InfluenceRegions already loaded
        if (influenceRegions.length) {
          return of(INFLUENCEREGIONS_LOAD_SUCCESS({ influenceRegions: influenceRegions }));
        } else if (loading) {
          return EMPTY;
        }

        // Load influenceRegions
        store.dispatch(INFLUENCEREGIONS_LOAD_SET());
        return priceService.getInfluenceRegions().pipe(
          tap(() => store.dispatch(INFLUENCEREGIONS_LOAD_SET())),
          map(result => INFLUENCEREGIONS_LOAD_SUCCESS({ influenceRegions: result.items })),
          catchError((error: APIerror) => of(INFLUENCEREGIONS_LOAD_FAILURE({ error: error })))
        )
      })
    )
  }, { functional: true }
)
