import { createReducer, on } from "@ngrx/store";
import { Fuel } from "@models/fuel/fuel.class";
import { FUELS_LOAD_FAILURE, FUELS_LOAD_SET, FUELS_LOAD_SUCCESS, FUELS_SET, FUELS_UPDATE } from "./fuels.actions";

export interface FuelState {
  fuels: Fuel[];
  loading: boolean;
}

export const initialState: FuelState = {
  fuels: [],
  loading: false
};

export const fuelsReducer = createReducer(
  initialState,
  on(FUELS_SET, (state, { fuels: fuels }) => {
    return { ...state, fuels: fuels };
  }),
  on(FUELS_UPDATE, (state, { fuel }) => {
    const newState = [...state.fuels];
    const index = newState.findIndex(f => f.id === fuel?.id);
    if (index !== -1) newState[index] = fuel;
    else newState.push(fuel);

    return { ...state, fuels: newState };
  }),
  on(FUELS_LOAD_SET, (state) => {
    return { ...state, loading: true };
  }),
  on(FUELS_LOAD_SUCCESS, (state, { fuels }) => {
    return { ...state, fuels: fuels, loading: false };
  }),
  on(FUELS_LOAD_FAILURE, (state) => {
    return { ...state, loading: false };
  })
);
