import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { roleGuard } from './core/guards/role.guard';
import { homeGuard } from './core/helpers/homeRedirect.helper';

// NOTE: RoleGuard config is set in RoleGuard => AccessHelper

const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/home/home.component').then((m) => m.HomeComponent),
    canActivate: [homeGuard]
  },
  {
    path: 'alarms',
    loadComponent: () =>
      import('./components/operations/alarms/alarms.component').then((m) => m.AlarmsComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'changelog',
    loadComponent: () =>
      import('./components/utility-views/changelog/changelog.component').then((m) => m.ChangelogComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'stations',
    loadComponent: () =>
      import('./components/station/station/station.component').then((m) => m.StationComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'products',
    loadComponent: () =>
      import('./components/fuel/fuel/fuel.component').then((m) => m.FuelComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'deliveries',
    loadComponent: () =>
      import('./components/deliveries/deliveries/deliveries.component').then((m) => m.DeliveriesComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'tanks',
    loadComponent: () =>
      import('./components/tank/tank/tank.component').then((m) => m.TankComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'settlements',
    loadComponent: () =>
      import('./components/settlement/settlement/settlement.component').then((m) => m.SettlementComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'deviations',
    loadComponent: () =>
      import('./components/deviation/deviations/deviation.component').then((m) => m.DeviationComponent),
    canActivate: [MsalGuard, roleGuard]
  },
  {
    path: 'onboarding',
    loadComponent: () =>
      import('./components/onboarding/onboarding/onboarding.component').then((m) => m.OnboardingComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'users',
    loadComponent: () =>
      import('./components/users/users/users.component').then((m) => m.UsersComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'sales',
    loadComponent: () =>
      import('./components/sales/sales.component').then(m => m.SalesComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'price',
    loadComponent: () =>
      import('./components/price/price/price.component').then(m => m.PriceComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'newPriceObservations',
    loadComponent: () =>
      import('./components/price-observation/price-observation-new/price-observation.component').then(m => m.PriceObservationComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'priceObservationsHistory',
    loadComponent: () =>
      import('./components/price-observation/price-observation-table/price-observation-table.component').then(m => m.PriceObservationTableComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'observationRoutes',
    loadComponent: () =>
      import('./components/observation-routes/observation-routes.component').then(m => m.ObservationRoutesComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'priceLift',
    loadComponent: () =>
      import('./components/price-lift/price-lift-overview/price-lift.component').then(m => m.PriceLiftComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'priceStationDetails',
    loadComponent: () =>
      import('./components/price/components/price-station-details/price-station-details.component').then(m => m.PriceStationDetailsComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'priceRules',
    loadComponent: () =>
      import('./components/price-config/price-config.component').then(m => m.PriceConfigComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'competitorConfig',
    loadComponent: () =>
      import('./components/price-competitors/price-competitors.component').then(m => m.PriceCompetitorsComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'relays',
    loadComponent: () =>
      import('./components/operations/relays/relay-table/relay-table.component').then(m => m.RelayTableComponent),
    canActivate: [MsalGuard, roleGuard],
  },
  {
    path: 'my-page',
    loadComponent: () =>
      import('./components/utility-views/my-page/my-page.component').then(m => m.MyPageComponent),
    canActivate: [MsalGuard, roleGuard],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
