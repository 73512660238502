import { RoleEnum } from "../common/roles.enum";

export class MenuOption {
  name: string;
  roleAccess: RoleEnum[];
  icon?: string;
  redirectUrl?: string;
  subOptions?: MenuOption[];

  constructor(name: string, roles: RoleEnum[], url: string | undefined = undefined, icon: string | undefined = undefined, subOptions: MenuOption[] | undefined = undefined) {
    this.name = name;
    this.roleAccess = roles;
    this.icon = icon;
    this.redirectUrl = url;
    this.subOptions = subOptions;
  }
}
