import { inject } from "@angular/core";
import { PriceService } from "@app/core/http/price.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { COMPETITORSTATIONS_FETCH, COMPETITORSTATIONS_LOAD_FAILURE, COMPETITORSTATIONS_LOAD_SET, COMPETITORSTATIONS_LOAD_SUCCESS } from "./competitorStations.actions";
import { concatLatestFrom } from "@ngrx/operators";
import { get_competitorStations, get_competitorStationsLoading } from "./competitorStations.selectors";
import { catchError, EMPTY, map, mergeMap, of, tap } from "rxjs";
import { APIerror } from "@app/models/common/error.class";

export const loadCompetitorStations$ = createEffect(
  (
    $actions = inject(Actions),
    priceService = inject(PriceService),
    store = inject(Store)
  ) => {
    return $actions.pipe(
      ofType(COMPETITORSTATIONS_FETCH),
      concatLatestFrom(() => [
        store.pipe(select(get_competitorStations())),
        store.pipe(select(get_competitorStationsLoading()))
      ]),
      // First element of the array is the action, which isn't needed. Empty and unnamed value to not trigger linting errors
      mergeMap(([, competitorStations, loading]) => {
        // CompetitorStations already loaded
        if (competitorStations.length) {
          return of(COMPETITORSTATIONS_LOAD_SUCCESS({ competitorStations: competitorStations }));
        } else if (loading) {
          return EMPTY;
        }

        // Load competitorStations
        store.dispatch(COMPETITORSTATIONS_LOAD_SET());
        return priceService.getCompetitorStations().pipe(
          tap(() => store.dispatch(COMPETITORSTATIONS_LOAD_SET())),
          map(result => COMPETITORSTATIONS_LOAD_SUCCESS({ competitorStations: result.items })),
          catchError((error: APIerror) => of(COMPETITORSTATIONS_LOAD_FAILURE({ error: error })))
        )
      })
    )
  }, { functional: true }
)
